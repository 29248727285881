<script setup>
import entrepreneurOfferDesktopImg from '@/assets/images/statics/entrepreneur-offer-benefits.jpg';
import entrepreneurOfferMobileImg from '@/assets/images/statics/entrepreneur-offer-benefits-mobile.jpg';
import drivePlusImg from '@/assets/images/statics/drive-plus.jpg';
import drivePlusAssistanceIcon from '@/components/Vector/DrivePlusAssistanceCircle.vue';
import drivePlusPhoneIcon from '@/components/Vector/DrivePlusPhoneCircle.vue';
import drivePlusSupportIcon from '@/components/Vector/DrivePlusSupportCircle.vue';
import drivePlusGpsIcon from '@/components/Vector/DrivePlusGpsCircle.vue';

useHead({
  title: "Leasing samochodu dla przedsiębiorcy - Oferta - Vehis.pl",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
  link: [
    {rel: 'canonical', href: `https://vehis.pl${useRoute().fullPath}`}
  ]

});

const {gte, lte} = useBreakpoints();

const backend = useBackend();

const slides = useSlides();

const refControl = ref();
const refSlideNavigation = ref();

onMounted(() => slides.initSlides(refControl, refSlideNavigation))
const fields = [
  [    {
      title: 'Samochody w leasing dla firm — elastyczna forma finansowania dla przedsiębiorców',
      content: 'Leasing samochodowy to jedna z najpopularniejszych metod finansowania pojazdów w firmach. Pozwala przedsiębiorcom na korzystanie z samochodów bez konieczności ich zakupu za pełną kwotę od razu. Samochody w leasingu dla firm dają możliwość użytkowania nowoczesnych aut przy minimalnych kosztach początkowych, co szczególnie doceniają przedsiębiorcy szukający elastycznych rozwiązań finansowych. Skorzystanie z niskich kosztów całkowitych leasingu jest w takim wypadku atrakcyjnym rozwiązaniem.<br/><br/>Leasing jest dostępny dla różnych rodzajów działalności, w tym dla firm jednoosobowych oraz nowych przedsiębiorstw, które dopiero rozpoczynają swoją działalność. W ofercie VEHIS znajduje się szeroki wybór modeli aut, od samochodów osobowych po samochody dostawcze, co pozwala na dopasowanie pojazdu do specyficznych potrzeb danej firmy.'
    },
    {
      title: 'Czy opłaca się wziąć samochód w leasing na firmę? Korzyści',
      content: 'Leasing samochodu na firmę wiąże się z wieloma korzyściami.<br/><br/><ul class="list-disc"><li>Przede wszystkim, pozwala na obniżenie kosztów uzyskania przychodu, ponieważ raty leasingowe oraz opłata wstępna mogą być zaliczone do kosztów działalności.</li><li>Dodatkowo leasing operacyjny umożliwia uniknięcie jednorazowego dużego obciążenia finansowego, ponieważ przedsiębiorca płaci stałą miesięczną opłatę, co ułatwia planowanie budżetu firmy.</li><li>Leasing na firmę to również elastyczność w kwestii zakończenia umowy – po jej zakończeniu możesz: podjąć decyzję o wykupie pojazdu lub wymianie go na nowy model w ramach kolejnej umowy leasingowej.</li></ul><br/><br/>Dzięki temu przedsiębiorcy mają możliwość korzystania z najnowszych technologii i modeli aut, co może zwiększać prestiż firmy oraz komfort pracy.'
    }
  ],
  [
    {
      title: 'Leasing dla nowych firm - korzystne warunki',
      content: 'Leasing dla nowych firm to idealne rozwiązanie dla przedsiębiorców, którzy dopiero rozpoczynają swoją działalność i nie chcą angażować dużych środków własnych. Warunki leasingu dla nowych firm są dostosowane do ich specyficznych potrzeb – można skorzystać z opcji leasingu z niską opłatą wstępną lub bez konieczności wkładu własnego, co ułatwia rozpoczęcie biznesu.<br/><br/>Leasing samochodowy dla nowych firm obejmuje zarówno leasing aut nowych, jak i leasing samochodów używanych, dzięki czemu każdy przedsiębiorca znajdzie odpowiednią ofertę dla siebie. W zależności od wybranej opcji możliwe jest skorzystanie z leasingu operacyjnego, który charakteryzuje się niższymi miesięcznymi ratami oraz możliwością rozliczenia podatku VAT.',
    }
  ],
  [
    {
      title: 'Leasing samochodu na firmę jednoosobową - niski wkład własny i elastyczne warunki',
      content: 'Dla właścicieli jednoosobowych działalności gospodarczych leasing samochodu na firmę to atrakcyjna forma finansowania. Dzięki elastycznym warunkom umowy można wybrać odpowiednią długość trwania umowy leasingowej oraz wysokość miesięcznych rat, dopasowując je do specyfiki prowadzonej działalności. Leasing na firmę jednoosobową pozwala na uzyskanie dostępu do nowoczesnych samochodów bez potrzeby angażowania dużych środków finansowych na początek.<br/><br/>Leasing auta na firmę jednoosobową to również wygodne rozwiązanie z punktu widzenia rozliczeń podatkowych – miesięczne raty leasingowe mogą być zaliczane do kosztów uzyskania przychodu, co pozwala na obniżenie podatku dochodowego. Dodatkowo leasing samochodu używanego jest dostępny na równie korzystnych warunkach jak leasing auta nowego, co daje większą elastyczność wyboru pojazdu.'
    },
    {
      title: 'Leasing samochodu dostawczego na firmę - bogata oferta, minimum formalności',
      content: 'Leasing samochodu dostawczego na firmę to rozwiązanie dedykowane przedsiębiorcom, którzy potrzebują niezawodnych środków transportu. Oferta obejmuje leasing zarówno aut nowych, jak i używanych, co pozwala na dopasowanie pojazdu do specyfiki prowadzonej działalności oraz dostępnego budżetu. Minimalne formalności oraz szybki proces decyzyjny sprawiają, że leasing firmowy jest dostępny niemal od ręki, co pozwala na sprawne rozpoczęcie działalności.<br/><br/>Firmy leasingowe oferują elastyczne warunki umowy leasingowej, umożliwiające dopasowanie długości umowy oraz wysokości rat do indywidualnych potrzeb przedsiębiorcy. W przypadku leasingu samochodu dostawczego można skorzystać również z opcji promocyjnego pakietu ubezpieczeń komunikacyjnych, co obniża całkowite koszty użytkowania pojazdu.'
    }
  ],
  [
    {
      title: 'Jak wziąć samochód w leasing na firmę z VEHIS?',
      content: 'VEHIS oferuje kompleksowe usługi leasingowe. Proces ubiegania się o leasing samochodowy jest prosty i szybki. Wystarczy wypełnić wniosek leasingowy, wybrać odpowiedni model auta oraz dostosować warunki umowy leasingowej do swoich potrzeb.<br/><br/>Po podpisaniu umowy leasingowej klient otrzymuje samochód i może od razu zacząć z niego korzystać. W czasie trwania umowy leasingu operacyjnego VEHIS oferuje również program wsparcia serwisowego, co dodatkowo zwiększa komfort użytkowania pojazdu. Na koniec finansowania podejmujesz decyzję, czy chcesz wykupić auto, czy też wymienić je na nowszy model w ramach nowej umowy.'
    }
  ],
  [
    {
      title: 'Samochody w leasing dla firm z VEHIS',
      content: 'Wiesz już, na czym polega leasing samochodu. VEHIS oferuje bogatą ofertę samochodów dla firm, obejmującą różnorodne modele aut dostawczych i osobowych. Oferujemy tradycyjny leasing operacyjny, co pozwala na wybór najkorzystniejszej formy finansowania dla każdego przedsiębiorcy. W ramach umowy leasingowej można skorzystać z atrakcyjnych pakietów ubezpieczeń, co obniża koszty całkowite użytkowania pojazdu.<br/><br/>Dzięki elastycznym warunkom finansowania oraz szerokiemu wachlarzowi dostępnych samochodów, leasing dla firm staje się coraz bardziej popularnym rozwiązaniem. Niezależnie od tego, czy potrzebujesz auta osobowego, dostawczego czy używanego, VEHIS pomoże ci wybrać najlepszą opcję, abyś mógł efektywnie zarządzać swoją flotą pojazdów.'
    }
  ]
];

const faqData =  [
  {
    question: "Jakie dokumenty będą potrzebne do złożenia wniosku o finansowanie?",
    answer:
      "Jeżeli prowadzisz działalność gospodarczą wystarczy, że wyślesz do nas prosty wniosek, w którym m.in. podasz nam numer NIP swojej firmy, dane z dokumentu tożsamości i wyrazisz zgodę na weryfikację w bazach (może być telefonicznie). Jeżeli nie prowadzisz działalności oprócz wniosku i zgód poprosimy Cię dodatkowo o przesłanie oświadczenia o miejscu zatrudnienia i dochodzie.",
  },
  {
    question: "Jak długo muszę prowadzić działalność, żeby dostać leasing dla przedsiębiorców w VEHIS?",
    answer:
      "W VEHIS finansujemy startupy już od pierwszego dnia działalności gospodarczej.",
  },
  {
    question: "Czy muszę być obywatelem Polski, żeby sfinansować samochód w VEHIS?",
    answer:
      "Nie musisz być obywatelem Polski. Wystarczy, że dysponujesz ważną kartą pobytu w Polsce lub posiadasz spółkę zarejestrowaną w Polsce.",
  },
  {
    question:
      "Na czym polega możliwość wymiany lub zwrotu samochodu przed końcem umowy?",
    answer:
      "W naszej umowie leasingu wprowadziliśmy możliwość jej przedterminowego zakończenia bez podawania przyczyny. W dowolnym momencie możesz zgłosić do nas chęć rozliczenia i zakończenia umowy. Zestawimy wówczas Twoje zobowiązanie z prognozowaną wartością samochodu (wstępna wycena na bazie Eurotax). Jeżeli pozostała do spłaty kwota przewyższy wartość samochodu, to wpłacisz nam różnicę w formie kaucji i możesz oddać samochód do VEHIS. Od tego momentu nie będziemy wystawiali kolejnych faktur i wystawimy Twój samochód na sprzedaż. Ty też możesz pozyskać Klienta we własnym zakresie! Po sprzedaży nastąpi rozliczenie, w którym dodatkowo uwzględniamy zwrot z niewykorzystanej części ubezpieczenia. Jeżeli po finalnym rozliczeniu zostanie nadwyżka to w całości Ci ją wypłacimy. Jeżeli przychód ze sprzedaży i zwrot z ubezpieczenia nie pokryją wartości zobowiązania będziesz musiał dopłacić różnicę (w tym celu wykorzystamy kaucję, o której piszemy powyżej). Po zamknięciu całej transakcji możesz wziąć leasing na inny interesujący Cię samochód lub po prostu zakończyć naszą współpracę.",
  },
  {
    question: "Czy mogę wziąć leasing na samochód spoza oferty VEHIS?",
    answer:
      "Choć oferta VEHIS obejmuje wszystkie najpopularniejsze segmenty i większość marek dostępnych w Polsce to może się zdarzyć, że swój upragniony samochód znajdziesz u innego dostawcy. Oczywiście my nadal bardzo chętnie go sfinansujemy! W VEHIS finansujemy samochody osobowe i dostawcze do 3,5 t, nowe i używane do 5 lat z maksymalnym przebiegiem 200 tys. km. Samochód musi mieć dowód zakupu w postaci faktury VAT lub faktury VAT marża. ",
  },
  {
    question: "Czy mogę zmienić termin płatności rat?",
    answer:
      "Tak, w trakcie trwania umowy leasingowej masz możliwość zmiany terminu płatności raty w ramach jednego miesiąca kalendarzowego. Nasze Biuro Obsługi Klienta jest do Twojej dyspozycji. Chętnie dostosujemy harmonogram płatności do Twoich potrzeb.",
  }
]
const steps = [
  '<p class="mb-2 font-normal">Możesz wykupić</p> Na koniec umowy wpłać ustaloną na początku wartość wykupu i przejmij samochód na własność.',
  '<p class="mb-2 font-normal">Możesz zwrócić</p> Oddaj samochód do VEHIS bez spłaty wartości wykupu.',
  '<p class="mb-2 font-normal">Możesz przedłużyć umowę</p> Rozłóż wartość wykupu na dogodne raty i przedłuż z nami umowę.',
  '<p class="mb-2 font-normal">Możesz zmienić plany przed końcem umowy</p> W każdej chwili i bez podawania przyczyny możesz zwrócić samochód do VEHIS i na przykład wymienić go na inny model.'
];

const insurancePromoBoxesData = 
    [
        {
            title: 'Renomowani ubezpieczyciele',
            description: 'PZU, Ergo Hestia, Axa, Benefia.'
        },
        {
            title: 'Pakiet OC/AC/NNW',
            description: 'z gwarancją niezmienności składki przez wiele lat i możliwością rozłożenia na raty.'
        },
        {
            title: 'Ubezpieczenie GAP',
            description: 'pokrywające różnicę między odszkodowaniem z OC/AC a wartością samochodu z dnia zakupu.'
        },
    ];

   const featuresData =  [
      {
          title: 'Assistance na Polskę i Europę.',
          icon: drivePlusAssistanceIcon
      },
      {
          title: 'Serwisowanie samochodu z rabatami dzięki aplikacji mobilnej.',
          icon: drivePlusPhoneIcon
      },
      {
          title: 'Wsparcie w procesie obsługi i rozliczenia szkód komunikacyjnych.',
          icon: drivePlusSupportIcon
      },
      {
          title: 'GPS wraz z aplikacją - bezpieczeństwo i zarządzanie samochodem.',
          icon: drivePlusGpsIcon
      },
  ]
  const formOpened = ref(false);
const isMobileView = computed(() => lte('lg'));
const openForm = (event) => {
    if(isMobileView.value) {
      event.preventDefault();
      formOpened.value = true;
    } 
}

const heroBoxesData = [
            {
                heading: 'Wysoki rabat',
                txt: 'na samochód.'
            },
            {
                heading: 'W 100% online',
                txt: 'lub z osobistym doradcą.'
            },
            {
                heading: 'Uproszczona procedura',
                txt: 'do 700 tys. zł.'
            }
        ]

      
const { data: blocks } = await useAsyncData(async () => {
  const response = await backend.getSeoFooterBlocks('oferta-dla-przedsiebiorcyy');
  return response.data?.blocks?.length ? response.data : [];
});

const fieldsSeo = computed(() =>
  blocks.value?.blocks?.map((block) => block.boxes || []) || []
);

const headers = computed(() =>
  blocks.value?.blocks?.map((block) => block.header || null) || []
);

const hasNewBlocks = computed(() => {
  return blocks.value?.blocks?.length > 0;
});

</script>

<template>
      <div class="sticky top-[97px] md:top-[111px] z-50">
    <Container no-paddings gray>
      <MenuScrolled>
        <AnchorJump>Samochód na raty</AnchorJump>
        <AnchorJump to="how-to-work">Jak działa</AnchorJump>
        <AnchorJump to="benefits">Korzyści</AnchorJump>
        <AnchorJump to="drive-plus">Pakiet DRIVE+</AnchorJump>
        <AnchorJump to="insurance">Ubezpieczenie</AnchorJump>
        <AnchorJump to="faq">FAQ</AnchorJump>
        <AnchorJump to="contactSection">Zamów ofertę</AnchorJump>
      </MenuScrolled>
    </Container>
  </div>
  <Container noPaddings>
    <StaticMainHeader 
      bigAzureH1
      :heroBoxesData="heroBoxesData"
      :h1="'Leasing samochodu dla przedsiębiorcy'">
      <template v-slot:mainImage>
        <img class="w-full" src="@/assets/images/statics/offer-entrepreneur.jpg" alt="" />
      </template>
      <template v-slot:headerDescription>
        <p class="text-center lg:text-left text-2xl lg:text-3xl mt-12 text-coolGray-700 font-normal">
          Świetny wybór dla biznesu, już od pierwszego dnia działalności.
        </p>  
      </template>
    </StaticMainHeader>
  </Container>
    <div class="bg-cool-gray-light hidden lg:block">
      <div class="py-6">
        <FormContact analytics-form-type="offer-entrepreneur" noContainer :customTextBtn="'Zamawiam kontakt'" :title="'Chcesz porozmawiać z naszym doradcą? Możesz to zrobić w każdym momencie'" standalone />
      </div>
    </div>
    <div class="pt-16">
      <Container>
        <SeoBrandsSlider />
      </Container>
    </div>
    <!-- <Container>
      <StaticInfoWithImage>
          <template v-slot:image>
            <img class="w-full my-12" src="@/assets/images/statics/offer-consumer-1.jpg" alt="" />
            <img src="" alt="">
          </template>
      </StaticInfoWithImage>
  </Container> -->
  <section id="how-to-work" class="pt-16">
    <StaticInfoOverlay 
      :ctaTxt="'Zapytaj o ofertę'"
    />
  </section>
    <section id="benefits">
      <Container>
        <img class="mx-auto my-16" :src="gte('sm') ? entrepreneurOfferDesktopImg : entrepreneurOfferMobileImg" alt="blackweek benefits"/>
      </Container>
    </section>
  <section id="drive-plus">
    <Container>
      <StaticImageWithFeatures :featuresData="featuresData" :mainPhoto="{src: drivePlusImg, alt: 'drive plus img'}" />
    </Container>
  </section>
  <section id="insurance">
    <Container>
      <StaticInfoWithImage
        :title="'Ubezpieczenie pełnej wartości samochodu'" 
        :description="''" 
        :boxesData="insurancePromoBoxesData">
            <template v-slot:image>
              <picture>
                <source srcset="@/assets/images/statics/offer-entrepreneur-2.jpg" media="(min-width: 688px)" />
                <img class="w-full my-12" src="@/assets/images/statics/offer-entrepreneur-2-mobile.jpg" alt="" />
              </picture>
            </template>
        </StaticInfoWithImage>
      </Container>
    </section>
    <section class="bg-cool-gray-light mb-[112px]" id="how-to-start">
    <Container>
      <BlockHowTo
        hideArrows
        noSelected
        :negMargin="false"
        textLeft 
        :textCenter="false"  
        :title="'Zakończenie umowy? Masz czas na decyzję!'"
        hideDescription
        :steps="steps"
        :customHeadingClass="'text-3xl text-azure-dark text-center'"
        >
        <template v-slot:headerDescription>
          <p class="mt-6 text-center text-xl text-cool-gray-700 max-w-4xl mx-auto">
            Nie zastanawiaj się nad tym, co wydarzy się za kilka lat. W ofercie samochodu na raty o sposobie zakończenia umowy zdecydujesz we właściwym dla Ciebie momencie.
          </p>
        </template>
        </BlockHowTo>
    </Container>
  </section>
    <section id="faq">
        <StaticFaq :faqData="faqData"/>
      </section>
      <section id="contactSection" class="mb-20">
        <Container class="2xl:block">
          <FormContact :customFontWeight="'font-medium'" class="font-medium  class="font-medium  analytics-form-type="offer-entrepreneur" title='Zamów ofertę! <p class="text-azure inline-block">Zostaw kontakt</p>' standalone />
      </Container>
      </section>
    <div class="bg-cool-gray-light">
      <Container>
        <SeoBrandsCloud noOuterPaddings textLeft/>
      </Container>
    </div>
    <Container v-if="hasNewBlocks && fieldsSeo.some((field) => field.length > 0)">
      <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
      <h2 class="mx-auto text-center text-lg font-normal mb-6">
        {{ blocks?.title }}
      </h2>

      <template v-for="(fieldItems, index) in fieldsSeo" :key="`field-${index}`">
        <template v-if="fieldItems.length > 0">
          <div v-if="index" class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
          <h2 v-if="headers[index]" class="font-normal mb-2 text-lg">
            {{ headers[index] }}
          </h2>
          <div class="flex gap-6 flex-wrap md:flex-nowrap">
            <SeoContentBlock
              v-for="(item, idx) in fieldItems"
              :key="`field-${index}-${idx}`"
              :data="item"
              class="w-full"
            />
          </div>
        </template>
      </template>
    </Container>
    <Container v-else class="mt-4">
      <h2 class="mx-auto text-center text-lg font-normal mb-6">
        Samochody w leasingu dla firm
      </h2>
      <p class="text-sm font-extralight mb-6">
        Samochody w leasingu dla firm to wygodne rozwiązanie, które pozwala na użytkowanie nowoczesnych pojazdów bez konieczności ich zakupu. Dzięki temu przedsiębiorcy mogą cieszyć się niskimi kosztami całkowitych leasingu, co przekłada się na korzystne warunki finansowe. Leasing samochodu osobowego pozwala na elastyczne dopasowanie warunków umowy do potrzeb firmy, a po zakończeniu umowy przedsiębiorca może podjąć decyzję o wykupie auta, dokonaniu zwrotu lub wymianie na nowy model. Gdy nadejdzie koniec umowy, istnieje możliwość przedłużenia leasingu lub skorzystania z kolejnych opcji finansowania.
      </p>
      <template v-for="(fieldItems, index) in fields" :key="`field-${index}`">
        <template v-if="fieldItems?.length > 0">
        <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
          <div class="flex gap-6 flex-wrap md:flex-nowrap">
            <SeoContentBlock
            small
              v-for="(item, idx) in fieldItems"
              :key="`field-${index}-${idx}`"
              :data="item"
              class="w-full"
            />
          </div>
        </template>
      </template>
    </Container>
    <FormContact v-model:opened="formOpened" widthFull :customTextBtn="'Zamów kontakt'" docking analytics-form-type="offer-entrepreneur" title='Zamów ofertę! <p class="text-azure inline-block">Zostaw kontakt!</p>' class="lg:hidden"/>
</template>
<style>
html {
  scroll-behavior: smooth;

}

#contactSection {
  scroll-margin-top: 150px;
}
</style>